@font-face {
    font-family: WeddingFont;
    src: url(Natalia.otf);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "WeddingFont";
}

html {
    min-height: 100%;
    background-image: url(/public/images/taustakuva.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.ContainerAlpha {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0rem;
}

.ContainerAlpha div {
    color: #000000;
    font-size: 100px;
}

.ContainerAlpha p {
    color: #000000;
    font-size: 70px;
}

.border {
    border-top: 6px solid #000000;
    width: 50%;
    margin-left: 25%;

}

header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0px;
}

li,
a {
    color: #6006b4;
    font-size: 60px;
    text-decoration: none;
}

.nav_links {
    list-style: none;
}

.nav_links li {
    display: inline-block;
    padding: 0px 100px;
    border-style: solid;
    color: #000000;
    border-width: 6px 4px;
}

.Title {
    color: #6006b4;
    text-align: center;
    font-size: 90px;
    padding: 10px;
}

.Title2 {
    color: #6006b4;
    text-align: center;
    font-size: 50px;
    padding: 10px;
}

.sijantibox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 0.3px;
    padding-bottom: 40px;
}

.item {
    border-style: solid;
    text-align: center;
    font-size: 40px;
    padding: 20px;
}

.infotext {
    font-size: 40px;
    text-align: center;
    padding: 10px 0px;
}

.info1 {
    padding: 0px 100px;
}

.info2 {
    padding: 0px 200px;
}

.parkki {
    text-align: center;
    font-size: 40px;
}

.fullboxi {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.hääboxi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.hääteksti {
    text-align: center;
    font-size: 40px;
}

.hääteksti2 {
    padding: 5px 0px;
}

.hääteksti2 p {
    font-size: 30px;

}

.video {
    text-align: center;
}

@media only screen and (max-width: 1024px) {


    .ContainerAlpha div {
        font-size: 75px;
    }

    .ContainerAlpha p {
        font-size: 75px;
    }

    li,
    a {
        font-size: 40px;
    }

    header {
        padding: 30px 0px;
    }

    .nav_links li {
        padding: 0px 50px;
    }

}

@media only screen and (max-width: 600px) {

    .ContainerAlpha {
        min-height: 150px;
    }

    .ContainerAlpha div {
        font-size: 60px;
    }

    .ContainerAlpha p {
        font-size: 60px;
    }

    .hääteksti {
        font-size: 20px;
        padding: 0px 10px;
    }

    .hääteksti2 p {
        font-size: 15px;
    }

    .info1 {
        font-size: 20px;
        padding: 10px;
    }

    .info2 {
        font-size: 20px;
        padding: 0px 10px;
    }

    .parkki {
        font-size: 20px;
    }

    .Title {
        font-size: 60px;
    }

    .Title2 {
        font-size: 30px;
    }

    .item {
        font-size: 20px;
        padding: 10px;
    }

    header {
        padding: 10px 0px;
    }

    .nav_links li {
        padding: 0px 20px;
    }

    li,
    a {
        font-size: 30px;
    }

}